<template>
  <div>
    <v-skeleton-loader class="mx-auto ml-2 mr-2" type="card" :loading="loading">
      <!-- <v-container fluid class="mb-4"> -->
      <CommunityInfo
        :communityInfo="communityInfo"
        @onRelatedCommunityChanged="showRelatedCommunity"
        @onRelatedCommunityLoaded="relatedCommunityLoaded"
        @onRelatedCommunityLoading="relatedCommunitiesLoading = true"
      />
      <InternalBreadcrumbs :breadcrumbs="breadcrumbs" />
      <v-card ref="activeSection" elevation="0" outlined color="#f1f1f1">
        <v-card-title>New User</v-card-title>
        <v-card-text>
          <!-- Start Upload proposal section -->
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="newUser.firstName"
                    label="First Name"
                    :error-messages="firstNameErrors"
                    @input="$v.newUser.firstName.$touch()"
                    @blur="$v.newUser.firstName.$touch()"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="newUser.lastName"
                    label="Last Name"
                    :error-messages="lastNameErrors"
                    @input="$v.newUser.lastName.$touch()"
                    @blur="$v.newUser.lastName.$touch()"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="newUser.email"
                    label="Email"
                    :error-messages="emailErrors"
                    @input="$v.newUser.email.$touch()"
                    @blur="$v.newUser.email.$touch()"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <v-select
                    v-model="newUser.role"
                    :items="roleItems"
                    item-text="name"
                    item-value="value"
                    label="Choose role..."
                    :error-messages="roleErrors"
                    @input="$v.newUser.role.$touch()"
                    @blur="$v.newUser.role.$touch()"
                    required
                  ></v-select>
                </v-col>
              </v-row>
              <v-row v-if="isResident(newUser.role)">
                <v-col cols="12" md="6">
                  <v-textarea
                    v-model="newUser.address"
                    label="Address"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <v-autocomplete
                    v-model="newUser.communities"
                    :items="relatedCommunities"
                    :loading="relatedCommunitiesLoading"
                    item-text="communityName"
                    item-value="aspireId"
                    label="Choose communities..."
                    dense
                    multiple
                    solo
                    :error-messages="communitiesErrors"
                    :rules="[
                      (newUser.communities && newUser.communities.length > 0) ||
                        'Choose at least one community'
                    ]"
                    @input="$v.newUser.communities.$touch()"
                    @blur="$v.newUser.communities.$touch()"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="newUser.password"
                    label="Password"
                    :type="showPassword ? 'text' : 'password'"
                    @click:append.prevent="showPassword = !showPassword"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :error-messages="passwordErrors"
                    @input="$v.newUser.password.$touch()"
                    @blur="$v.newUser.password.$touch()"
                    required
                  ></v-text-field>
                  <p>
                    Password must be minimum of eight characters with at least
                    one capital and one lower case letter.
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="newUser.confirmPassword"
                    label="Confirm Password"
                    :type="showPassword ? 'text' : 'password'"
                    @click:append.prevent="showPassword = !showPassword"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :error-messages="passwordErrors"
                    @input="$v.newUser.confirmPassword.$touch()"
                    @blur="$v.newUser.confirmPassword.$touch()"
                    :rules="[
                      newUser.password === newUser.confirmPassword ||
                        'Passwords do not match'
                    ]"
                    required
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <div class="mt-2">
                <v-btn class="orange-bg white--text" @click="addUser">
                  Add User
                </v-btn>
              </div>
            </v-container>
          </v-form>
          <!-- End Upload proposal section -->
        </v-card-text>
      </v-card>

      <!-- </v-container> -->
    </v-skeleton-loader>
  </div>
</template>

<script>
import CommunityInfo from "@/view/components/CommunityInfo.vue";
import InternalBreadcrumbs from "@/view/components/DashboardInternalBreadcrumbs.vue";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import {
  GET_QUERY,
  POST_DATA,
  API_CUSTOMERS,
  API_USERS
} from "@/core/store/crud.module";
import crud from "@/core/helpers/crud";
import dateFormatter from "@/core/helpers/dateFormatter";
import stringFormatter from "@/core/helpers/stringFormatter";
import { validationMixin } from "vuelidate";
// import dataModels from "@/core/helpers/datamodels.js";
import { required } from "vuelidate/lib/validators";
// import moment from "moment";

export default {
  mixins: [validationMixin],
  components: {
    CommunityInfo,
    InternalBreadcrumbs
  },
  data: () => ({
    communityInfo: {},
    relatedCommunitiesLoading: false,
    relatedCommunities: [],
    showPassword: false,
    newUser: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      role: "",
      communities: [],
      address: ""
    },
    role: "",
    roleItems: [
      { name: "Property Manager", value: "Property Manager" },
      { name: "Board Member", value: "Board Member" },
      { name: "Landscape Committee", value: "Landscape Committee" },
      { name: "Tenant/Resident", value: "Tenant/Resident" },
      {
        name: "Tenant/Resident - Neighborhood",
        value: "Tenant/Resident - Neighborhood"
      }
    ],
    isLoading: false,
    loading: false,
    snackbarContent: "",
    snackbarColor: "#F06835",
    breadcrumbs: [],
    userProcessed: false,
    communitiesToProcess: []
  }),
  props: {
    customerId: {
      type: [Number, String],
      default: 0
    }
  },
  validations() {
    // return true;
    return {
      newUser: {
        firstName: {
          required
        },
        lastName: {
          required
        },
        role: {
          required
        },
        email: {
          required
        },
        password: {
          required
        },
        confirmPassword: {
          required
        },
        communities: {
          required
        }
      }
    };
  },
  watch: {
    communitiesToProcess: async function() {
      if (this.communitiesToProcess.length == 0) return;

      let processed = true;
      this.communitiesToProcess.forEach(function(elem) {
        processed = processed && elem.processed;
      });

      if (processed) {
        this.$router.push({
          name: "ManagePortalAccess",
          params: { customerId: this.customerId }
        });
      }
    }
  },
  async mounted() {},
  async created() {
    let self = this;
    self.loading = true;
    Promise.all([
      await this.getComunityInfo()
      // await this.getRecipients()
    ]).then(function() {
      self.loading = false;
    });
  },
  methods: {
    isResident(role) {
      return role == "Tenant/Resident";
    },
    relatedCommunityLoaded(communities) {
      this.relatedCommunities = communities;
      this.relatedCommunitiesLoading = false;

      const parsedCustomerId = parseInt(this.customerId);
      if (!isNaN(parsedCustomerId))
        this.newUser.communities.push(parsedCustomerId);
    },
    showRelatedCommunity(communityId) {
      this.$router.push({
        name: "CustomerDetails",
        params: { customerId: communityId }
      });
    },
    async getComunityInfo() {
      this.loading = true;

      let self = this;

      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_CUSTOMERS,
          id: this.customerId
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            self.communityInfo = crud.getPopulatedComunityInfo(response.data);
            self.createBreadcrumb();
            self.setInternalBreadcrumbs();
          } else {
            self.$snackbar.showMessage({
              content:
                response.data ||
                "Cannot receive Community details. Something went wrong!",
              color: "red"
            });
          }

          self.loading = false;
        });
    },
    addUser() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.newUser.userType = "Customer";
      this.newUser.userRoles = [this.newUser.role];
      this.newUser.userName = this.newUser.email;

      if (this.newUser.communities.length == 0) {
        const parsedCustomerId = parseInt(this.customerId);
        if (isNaN(parsedCustomerId)) {
          self.$snackbar.showMessage({
            content: "No community is specified.",
            color: "red"
          });
          return;
        }
        this.newUser.communities.push(parsedCustomerId);
      }
      // this.newUser.properties = this.newUser.communities.map(el => {
      //   return { id: el };
      // });

      let self = this;
      this.$store
        .dispatch(POST_DATA, {
          listName: `${API_USERS}`,
          data: this.newUser
        })
        .then(response => {
          if (response.status >= 200 && response.status <= 204) {
            if (response.data.error) {
              if (
                response.data.error ===
                "Cannot create user, email already in use."
              )
                self.snackbarContent =
                  "Cannot create user, email already in use.";
              else
                self.snackbarContent =
                  "User cannot be created. Email might be already in use. Also please check that password contains at least 8 symbols and include at least one uppercase letter, one lowercase letter.";
              self.snackbarColor = "red";
            } else {
              self.snackbarContent = "User has been created";

              let user = response.data;

              self.communitiesToProcess = self.newUser.communities.map(i => {
                return { id: i, processed: false };
              });

              self.userProcessed = false;

              self.newUser.communities.forEach(element => {
                self.$store
                  .dispatch(POST_DATA, {
                    listName: `${API_CUSTOMERS}/${element}/user/${user.identityId}`
                  })
                  .then(addResponse => {
                    if (
                      addResponse.status >= 200 &&
                      addResponse.status <= 204
                    ) {
                      self.snackbarColor = "green";
                      self.snackbarContent =
                        "User has been added to " +
                        self.relatedCommunities.find(
                          el => el.aspireId == element
                        ).communityName;

                      self.communitiesToProcess.find(
                        elem => elem.id == element
                      ).processed = true;

                      self.communitiesToProcess.push({
                        id: new Date(),
                        processed: true
                      });
                    }
                    self.$snackbar.showMessage({
                      content: self.snackbarContent,
                      color: self.snackbarColor
                    });
                  });
              });
            }
          } else {
            self.snackbarContent = response.data;
            self.snackbarColor = "red";
          }
          self.$snackbar.showMessage({
            content: self.snackbarContent,
            color: self.snackbarColor
          });
        })
        .catch(res => {
          let error =
            "User cannot be created. Email might be already in use. Also please check that password contains at least 8 symbols and include at least one uppercase letter, one lowercase letter.";
          if (res)
            error =
              "User cannot be created. Email might be already in use. Also please check that password contains at least 8 symbols and include at least one uppercase letter, one lowercase letter.";

          self.$snackbar.showMessage({
            content: error,
            color: "red"
          });
        });

      // this.saveCustomer();
    },
    setInternalBreadcrumbs() {
      this.breadcrumbs = [
        {
          title: this.communityInfo.name,
          route: "/customers/" + this.customerId
        },
        {
          title: "Manage Portal Access",
          route: "/customers/" + this.customerId + "/manage-portal-access"
        },
        { title: "New User" }
      ];
    },
    createBreadcrumb() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Customers", route: "/customers" },
        {
          title: this.communityInfo.name,
          route: "/customers/" + this.customerId
        },
        {
          title: "Manage Portal Access",
          route: "/customers/" + this.customerId + "/manage-portal-access"
        },
        { title: "New User" }
      ]);
    },
    formatDate(date) {
      return dateFormatter.formatEditDate(date);
    },
    formatDateUs(date) {
      return dateFormatter.formatDateUs(date);
      // return moment(date).format("MM/DD/YYYY");
    },
    capitalizeFirstLetter(string) {
      return stringFormatter.capitalizeFirstLetter(string);
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    }
  },
  computed: {
    firstNameErrors() {
      const errors = [];
      if (!this.$v.newUser.firstName.$dirty) return errors;
      !this.$v.newUser.firstName.required && errors.push("Required.");
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      if (!this.$v.newUser.lastName.$dirty) return errors;
      !this.$v.newUser.lastName.required && errors.push("Required.");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.newUser.password.$dirty) return errors;
      !this.$v.newUser.password.required && errors.push("Required.");
      return errors;
    },
    confirmPasswordErrors() {
      const errors = [];
      if (!this.$v.newUser.confirmPassword.$dirty) return errors;
      !this.$v.newUser.confirmPassword.required && errors.push("Required.");
      return errors;
    },
    roleErrors() {
      const errors = [];
      if (!this.$v.newUser.role.$dirty) return errors;
      !this.$v.newUser.role.required && errors.push("Required.");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.newUser.email.$dirty) return errors;
      !this.$v.newUser.email.required && errors.push("Required.");
      return errors;
    },
    communitiesErrors() {
      const errors = [];
      if (!this.$v.newUser.communities.$dirty) return errors;
      !this.$v.newUser.communities.required && errors.push("Required.");
      return errors;
    }
  }
};
</script>
